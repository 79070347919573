var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticStyle:{"min-height":"45rem"}},[_c('el-table',{attrs:{"data":_vm.list},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('el-pagination',{staticStyle:{"padding":".5rem"},attrs:{"background":"","layout":"total,prev, pager,next","total":_vm.total,"page-size":15,"current-page":_vm.page},on:{"current-change":_vm.pageChange}})]},proxy:true}])},[_c('el-table-column',{attrs:{"prop":"id","label":"编号","width":"80","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"缩略图","width":"80","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('img',{staticStyle:{"width":"3rem","height":"3rem","border-radius":".5rem"},attrs:{"src":_vm._f("tomedia")(s.row.pic)}})]}}])}),_c('el-table-column',{attrs:{"prop":"title","label":"名称","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"formatter":e=>e.status ? '已上架' : '未上架',"label":"上架","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"points","label":"积分","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"fee","label":"价格","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"stock","label":"库存","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"sales","label":"销量","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","width":"200","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
        name:'shop-plugin-points-mall-goods-edit',
        query:{
          id:s.row.id
        }
        })}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.del(s.row)}}},[_vm._v("删除")])]}}])},[_c('template',{slot:"header"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
        name:'shop-plugin-points-mall-goods-edit'
        })}}},[_vm._v("添加商品")])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }